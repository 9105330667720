import { default as indexily6rV3EBoMeta } from "/app/pages/certificates/index.vue?macro=true";
import { default as indexZlUF5c0NUNMeta } from "/app/pages/course-areas/[courseArea]/[subjectGroup]/index.vue?macro=true";
import { default as indexFb355T7rQ0Meta } from "/app/pages/course-areas/[courseArea]/courses/[courseSlug]--[courseId]/index.vue?macro=true";
import { default as index6sL1PirIpuMeta } from "/app/pages/course-areas/[courseArea]/courses/[courseSlug]--[courseId]/offerings/[offeringId]/apply/index.vue?macro=true";
import { default as indexX1DuSrOFfZMeta } from "/app/pages/course-areas/[courseArea]/courses/[courseSlug]--[courseId]/offerings/[offeringId]/before-you-begin/index.vue?macro=true";
import { default as index52R9ghZWOHMeta } from "/app/pages/course-areas/[courseArea]/courses/[courseSlug]--[courseId]/offerings/[offeringId]/index.vue?macro=true";
import { default as indexCc3hYtiN64Meta } from "/app/pages/course-areas/[courseArea]/courses/[courseSlug]--[courseId]/offerings/[offeringId]/review-and-confirm/index.vue?macro=true";
import { default as indexvjvdvEfB0wMeta } from "/app/pages/course-areas/[courseArea]/index.vue?macro=true";
import { default as index0DRWn37sFNMeta } from "/app/pages/course-areas/index.vue?macro=true";
import { default as indextus0RxRpLAMeta } from "/app/pages/course-search/index.vue?macro=true";
import { default as indexAdVDOkEyqxMeta } from "/app/pages/degrees/index.vue?macro=true";
import { default as indexUF0w3ugNweMeta } from "/app/pages/digital/index.vue?macro=true";
import { default as indexAU4KPYATzCMeta } from "/app/pages/diplomas/index.vue?macro=true";
import { default as global_45loaderePVPZaRr7oMeta } from "/app/pages/global-loader.vue?macro=true";
import { default as indexN6pT4Un8hYMeta } from "/app/pages/index.vue?macro=true";
import { default as indexHZzFMdGK0QMeta } from "/app/pages/information-sessions/index.vue?macro=true";
import { default as indexpS0bNV3ScIMeta } from "/app/pages/international/course-search/index.vue?macro=true";
import { default as indexGLy0JZ8q2GMeta } from "/app/pages/international/courses/[courseSlug]--[courseId]/index.vue?macro=true";
import { default as indexdKo5P0op5cMeta } from "/app/pages/international/index.vue?macro=true";
import { default as _91id_93Fg2SHVr8YlMeta } from "/app/pages/international/package/[courseName]/[id].vue?macro=true";
import { default as logoutHaVXWqg8UAMeta } from "/app/pages/logout.vue?macro=true";
import { default as indexAfAEtJAmSYMeta } from "/app/pages/short-courses/index.vue?macro=true";
import { default as shortlist9ciz9mo5n8Meta } from "/app/pages/shortlist.vue?macro=true";
import { default as indexBV1zPX3VR7Meta } from "/app/pages/sitemap/index.vue?macro=true";
import { default as sso_45callback_45errorN7gw8JVatfMeta } from "/app/pages/sso-callback-error.vue?macro=true";
import { default as sso_45callbackFacPdj5mMpMeta } from "/app/pages/sso-callback.vue?macro=true";
import { default as indexCi05Vc2xGlMeta } from "/app/pages/student-hub/index.vue?macro=true";
import { default as _91id_93vFgOiNfvIrMeta } from "/app/pages/study-seeker/[id].vue?macro=true";
import { default as study_45seekerzMrmKJ1QNqMeta } from "/app/pages/study-seeker.vue?macro=true";
import { default as tailwind_45testVvbDx87XH3Meta } from "/app/pages/tailwind-test.vue?macro=true";
import { default as accordionJTYshAQlQRMeta } from "/app/pages/testing/accordion.vue?macro=true";
import { default as accreditation_45logo5cAEUp5TPjMeta } from "/app/pages/testing/accreditation-logo.vue?macro=true";
import { default as app_45form_45checkboxim1e0BXG55Meta } from "/app/pages/testing/app-form-checkbox.vue?macro=true";
import { default as app_45form_45dateYi1lWa3nYjMeta } from "/app/pages/testing/app-form-date.vue?macro=true";
import { default as app_45form_45dropdownh87b7rwGfUMeta } from "/app/pages/testing/app-form-dropdown.vue?macro=true";
import { default as app_45form_45numberCA1AlF55cmMeta } from "/app/pages/testing/app-form-number.vue?macro=true";
import { default as app_45form_45radiozJpmt8yAJKMeta } from "/app/pages/testing/app-form-radio.vue?macro=true";
import { default as app_45form_45textAfvMksCpdqMeta } from "/app/pages/testing/app-form-text.vue?macro=true";
import { default as app_45formcUmPZ8Y59qMeta } from "/app/pages/testing/app-form.vue?macro=true";
import { default as app_45submitted_45messageQ1nz2UJCVCMeta } from "/app/pages/testing/app-submitted-message.vue?macro=true";
import { default as application_45offering_45cardfHgyfeloNBMeta } from "/app/pages/testing/application-offering-card.vue?macro=true";
import { default as application_45records9ku7AbIc8TMeta } from "/app/pages/testing/application-records.vue?macro=true";
import { default as application_45status_45chipmjTuW5sbzJMeta } from "/app/pages/testing/application-status-chip.vue?macro=true";
import { default as application_45status_45next12vJkls2SbMeta } from "/app/pages/testing/application-status-next.vue?macro=true";
import { default as attribute_45tileaEYVW6UggkMeta } from "/app/pages/testing/attribute-tile.vue?macro=true";
import { default as back_45to_45top_45buttonHzA12q2YhWMeta } from "/app/pages/testing/back-to-top-button.vue?macro=true";
import { default as brand_45list_45groupsatX9EGTk4Meta } from "/app/pages/testing/brand-list-group.vue?macro=true";
import { default as brand_45list_45itemjIokkB8uXCMeta } from "/app/pages/testing/brand-list-item.vue?macro=true";
import { default as brand_45listAAZM5MDdFyMeta } from "/app/pages/testing/brand-list.vue?macro=true";
import { default as breadcrumbsmIUzjWL32fMeta } from "/app/pages/testing/breadcrumbs.vue?macro=true";
import { default as campus_45detailsFX1KXX6vrJMeta } from "/app/pages/testing/campus-details.vue?macro=true";
import { default as campus_45facilitiesULD9eR8B7GMeta } from "/app/pages/testing/campus-facilities.vue?macro=true";
import { default as campus_45map_45cardGRh27jDax6Meta } from "/app/pages/testing/campus-map-card.vue?macro=true";
import { default as career_45guidegkib35yEZxMeta } from "/app/pages/testing/career-guide.vue?macro=true";
import { default as chipQEfedeqjQCMeta } from "/app/pages/testing/chip.vue?macro=true";
import { default as content_45areaVsPWvg4UbIMeta } from "/app/pages/testing/content-area.vue?macro=true";
import { default as content_45cardK6UjHS5QqaMeta } from "/app/pages/testing/content-card.vue?macro=true";
import { default as content_45cardsA6Rz5wueijMeta } from "/app/pages/testing/content-cards.vue?macro=true";
import { default as content_45search_45sortdMRZAWDPmkMeta } from "/app/pages/testing/content-search-sort.vue?macro=true";
import { default as content_45sectionMUQioxo4tfMeta } from "/app/pages/testing/content-section.vue?macro=true";
import { default as course_45area_45cardvdffTVhVI9Meta } from "/app/pages/testing/course-area-card.vue?macro=true";
import { default as course_45area_45mobile_45navGJLv5A86PKMeta } from "/app/pages/testing/course-area-mobile-nav.vue?macro=true";
import { default as course_45card_45accordionUi8Mba7m0AMeta } from "/app/pages/testing/course-card-accordion.vue?macro=true";
import { default as course_45cardqjE3X1an3FMeta } from "/app/pages/testing/course-card.vue?macro=true";
import { default as course_45desktop_45nav4b7aO4Si7UMeta } from "/app/pages/testing/course-desktop-nav.vue?macro=true";
import { default as course_45guidezskTr1tc7BMeta } from "/app/pages/testing/course-guide.vue?macro=true";
import { default as course_45mobile_45navCXNzANyFrbMeta } from "/app/pages/testing/course-mobile-nav.vue?macro=true";
import { default as course_45search_45cardmcs3NJeb5vMeta } from "/app/pages/testing/course-search-card.vue?macro=true";
import { default as course_45type_45filters_45mobilerFd9jy5iqlMeta } from "/app/pages/testing/course-type-filters-mobile.vue?macro=true";
import { default as course_45type_45filtersIBpiJPZ5qbMeta } from "/app/pages/testing/course-type-filters.vue?macro=true";
import { default as dashboard_45headeri7T0bQlbaGMeta } from "/app/pages/testing/dashboard-header.vue?macro=true";
import { default as draft_45applicationyEys1iKa67Meta } from "/app/pages/testing/draft-application.vue?macro=true";
import { default as duration_45filter_45section4RKtarjhXkMeta } from "/app/pages/testing/duration-filter-section.vue?macro=true";
import { default as enrolled_45applicationsQzbIrrkT7cMeta } from "/app/pages/testing/enrolled-applications.vue?macro=true";
import { default as environment_45variables1XYKSCb4dWMeta } from "/app/pages/testing/environment-variables.vue?macro=true";
import { default as error_45application_45cardLymLVfP2H9Meta } from "/app/pages/testing/error-application-card.vue?macro=true";
import { default as fee_45calculator_45containerxNpDK1w1iBMeta } from "/app/pages/testing/fee-calculator-container.vue?macro=true";
import { default as fee_45calculator_45controlsLAtG2lHvikMeta } from "/app/pages/testing/fee-calculator-controls.vue?macro=true";
import { default as fee_45calculator_45eligible9gCS9GnppDMeta } from "/app/pages/testing/fee-calculator-eligible.vue?macro=true";
import { default as fee_45calculator_45not_45eligibleCA4WZNmZcdMeta } from "/app/pages/testing/fee-calculator-not-eligible.vue?macro=true";
import { default as fee_45calculator_45stepper5SqMVr3iGZMeta } from "/app/pages/testing/fee-calculator-stepper.vue?macro=true";
import { default as fee_45detailsDR5SnTEAZMMeta } from "/app/pages/testing/fee-details.vue?macro=true";
import { default as fetch_45errorlhTVBGWDzOMeta } from "/app/pages/testing/fetch-error.vue?macro=true";
import { default as fetch_45loading4mLh8JU0W6Meta } from "/app/pages/testing/fetch-loading.vue?macro=true";
import { default as filter_45chipsYZVtm2i9k3Meta } from "/app/pages/testing/filter-chips.vue?macro=true";
import { default as filter_45dropdownQPQ5m6fDCxMeta } from "/app/pages/testing/filter-dropdown.vue?macro=true";
import { default as filter_45sectione2zR1343KBMeta } from "/app/pages/testing/filter-section.vue?macro=true";
import { default as google_45maps_45autocomplete8FqyWTXn7xMeta } from "/app/pages/testing/google-maps-autocomplete.vue?macro=true";
import { default as hed_45units_45listNAZUQnemhAMeta } from "/app/pages/testing/hed-units-list.vue?macro=true";
import { default as hed_45units_45tabs_45contentiJY45LQ9W4Meta } from "/app/pages/testing/hed-units-tabs-content.vue?macro=true";
import { default as hed_45units_45tabsCokqR3WLg5Meta } from "/app/pages/testing/hed-units-tabs.vue?macro=true";
import { default as help_45buttonsKfYJwT1vX9Meta } from "/app/pages/testing/help-buttons.vue?macro=true";
import { default as hero_45bannerzm5ImdZu2OMeta } from "/app/pages/testing/hero-banner.vue?macro=true";
import { default as indexoyco8prOz1Meta } from "/app/pages/testing/index.vue?macro=true";
import { default as info_45session_45cardgXZ1jrhYgfMeta } from "/app/pages/testing/info-session-card.vue?macro=true";
import { default as info_45session_45dropdownCxucgqa2WBMeta } from "/app/pages/testing/info-session-dropdown.vue?macro=true";
import { default as info_45session_45filter_45chipsMsEW3y3PDXMeta } from "/app/pages/testing/info-session-filter-chips.vue?macro=true";
import { default as info_45session_45filterstoXvrirWVsMeta } from "/app/pages/testing/info-session-filters.vue?macro=true";
import { default as int_45course_45cardKtn2iz9I4NMeta } from "/app/pages/testing/int-course-card.vue?macro=true";
import { default as int_45dropdownDdsqUodVg1Meta } from "/app/pages/testing/int-dropdown.vue?macro=true";
import { default as int_45filter_45chipseRcJyRtMnNMeta } from "/app/pages/testing/int-filter-chips.vue?macro=true";
import { default as int_45filter6HWMBCZK2DMeta } from "/app/pages/testing/int-filter.vue?macro=true";
import { default as int_45mobile_45filterBTHKqFDu7HMeta } from "/app/pages/testing/int-mobile-filter.vue?macro=true";
import { default as int_45package_45detailse6xPQYLjTvMeta } from "/app/pages/testing/int-package-details.vue?macro=true";
import { default as int_45search_45bar_45slotjmjNQ6XfxIMeta } from "/app/pages/testing/int-search-bar-slot.vue?macro=true";
import { default as latest_45partners_45listiaRZd8b31qMeta } from "/app/pages/testing/latest-partners-list.vue?macro=true";
import { default as lazy_45imgRHTHB75LzYMeta } from "/app/pages/testing/lazy-img.vue?macro=true";
import { default as lineclamp_45containerDG0zlyBDuYMeta } from "/app/pages/testing/lineclamp-container.vue?macro=true";
import { default as location_45filter_45sectiontilCd6PJUSMeta } from "/app/pages/testing/location-filter-section.vue?macro=true";
import { default as loggingPksQk7DKKAMeta } from "/app/pages/testing/logging.vue?macro=true";
import { default as mobile_45filters_45overlayYILslnuyAnMeta } from "/app/pages/testing/mobile-filters-overlay.vue?macro=true";
import { default as modalMsMiUhAnU1Meta } from "/app/pages/testing/modal.vue?macro=true";
import { default as offering_45cta_45button_45externalD5G6ocvMg4Meta } from "/app/pages/testing/offering-cta-button-external.vue?macro=true";
import { default as offering_45cta_45button_45internal3GSaPZrUfOMeta } from "/app/pages/testing/offering-cta-button-internal.vue?macro=true";
import { default as offering_45cta_45buttonb4suEaYud3Meta } from "/app/pages/testing/offering-cta-button.vue?macro=true";
import { default as offering_45detailsADvThk3pO8Meta } from "/app/pages/testing/offering-details.vue?macro=true";
import { default as offering_45list_45cardl4LnmIF4yaMeta } from "/app/pages/testing/offering-list-card.vue?macro=true";
import { default as offering_45listsmDrqdYVEuMeta } from "/app/pages/testing/offering-list.vue?macro=true";
import { default as offering_45searchoFtfJTHIGKMeta } from "/app/pages/testing/offering-search.vue?macro=true";
import { default as offering_45status_45chiphBZzdC4Gn6Meta } from "/app/pages/testing/offering-status-chip.vue?macro=true";
import { default as offering_45statusVkAtQrgRgiMeta } from "/app/pages/testing/offering-status.vue?macro=true";
import { default as offering_45summary_45tileNXPyVTtEeLMeta } from "/app/pages/testing/offering-summary-tile.vue?macro=true";
import { default as page_45header_45buttonsyefggFospCMeta } from "/app/pages/testing/page-header-buttons.vue?macro=true";
import { default as page_45headerwXNl5ZdFkcMeta } from "/app/pages/testing/page-header.vue?macro=true";
import { default as paginationjvilQAjvEdMeta } from "/app/pages/testing/pagination.vue?macro=true";
import { default as progress_45barHqvIPUmkwOMeta } from "/app/pages/testing/progress-bar.vue?macro=true";
import { default as promotion_45card2yVxCXTj8XMeta } from "/app/pages/testing/promotion-card.vue?macro=true";
import { default as promotionsNuypSL3xMpMeta } from "/app/pages/testing/promotions.vue?macro=true";
import { default as resourceswWBdlGOYqMMeta } from "/app/pages/testing/resources.vue?macro=true";
import { default as search_45autocompleteBIIP2vHTJhMeta } from "/app/pages/testing/search-autocomplete.vue?macro=true";
import { default as search_45filters4lGLWGwwQCMeta } from "/app/pages/testing/search-filters.vue?macro=true";
import { default as side_45nav6NTUJGkYVSMeta } from "/app/pages/testing/side-nav.vue?macro=true";
import { default as spinnerYR9XddG8PuMeta } from "/app/pages/testing/spinner.vue?macro=true";
import { default as spy_45scrollBHsyVXfQiPMeta } from "/app/pages/testing/spy-scroll.vue?macro=true";
import { default as static_45card_45group5IfgnJxfbLMeta } from "/app/pages/testing/static-card-group.vue?macro=true";
import { default as static_45cardB0ygfUDkAGMeta } from "/app/pages/testing/static-card.vue?macro=true";
import { default as stepper_45checkboxJS24gykTaeMeta } from "/app/pages/testing/stepper-checkbox.vue?macro=true";
import { default as stepper_45dropdown2C4yqLur6JMeta } from "/app/pages/testing/stepper-dropdown.vue?macro=true";
import { default as stepper_45numberAXnyBUSf24Meta } from "/app/pages/testing/stepper-number.vue?macro=true";
import { default as stepper_45postcodekkM4kBIWknMeta } from "/app/pages/testing/stepper-postcode.vue?macro=true";
import { default as stepper_45radioPiZ12fAVA5Meta } from "/app/pages/testing/stepper-radio.vue?macro=true";
import { default as study_45seeker_45controlsEbneIbxVpDMeta } from "/app/pages/testing/study-seeker-controls.vue?macro=true";
import { default as study_45seeker_45headerm9NEjAH6fBMeta } from "/app/pages/testing/study-seeker-header.vue?macro=true";
import { default as study_45seeker_45list_45itemON1MyKpdyrMeta } from "/app/pages/testing/study-seeker-list-item.vue?macro=true";
import { default as study_45seeker_45results_45listg1QEl4LFYzMeta } from "/app/pages/testing/study-seeker-results-list.vue?macro=true";
import { default as study_45seeker_45resultspqdmxNKicyMeta } from "/app/pages/testing/study-seeker-results.vue?macro=true";
import { default as study_45seeker_45stepper2QKuHkiR9jMeta } from "/app/pages/testing/study-seeker-stepper.vue?macro=true";
import { default as study_45seeker_45storeoqtiAVLYUNMeta } from "/app/pages/testing/study-seeker-store.vue?macro=true";
import { default as subjectgroup_45sidenavWtZZGNkd4GMeta } from "/app/pages/testing/subjectgroup-sidenav.vue?macro=true";
import { default as subsidy_45detailsHCEdWwd8PxMeta } from "/app/pages/testing/subsidy-details.vue?macro=true";
import { default as tableRw5NBSlkx5Meta } from "/app/pages/testing/table.vue?macro=true";
import { default as tabs_45genericvyhcr7feR3Meta } from "/app/pages/testing/tabs-generic.vue?macro=true";
import { default as tabs_45slotFiPYTTMIeGMeta } from "/app/pages/testing/tabs-slot.vue?macro=true";
import { default as tafe_45button5BfSGe0wozMeta } from "/app/pages/testing/tafe-button.vue?macro=true";
import { default as tafe_45linkmy2dAAAooMMeta } from "/app/pages/testing/tafe-link.vue?macro=true";
import { default as tafe_45tilelkUmHhDnBwMeta } from "/app/pages/testing/tafe-tile.vue?macro=true";
import { default as tafe_45tileshkPQbzeDXfMeta } from "/app/pages/testing/tafe-tiles.vue?macro=true";
import { default as testimonial_45cardyVx1bqVrojMeta } from "/app/pages/testing/testimonial-card.vue?macro=true";
import { default as testimonial_45cards6aPWw9ol1AMeta } from "/app/pages/testing/testimonial-cards.vue?macro=true";
import { default as tooltipVGjc4Yar6QMeta } from "/app/pages/testing/tooltip.vue?macro=true";
import { default as transition_45groupWdP7voFFVIMeta } from "/app/pages/testing/transition-group.vue?macro=true";
import { default as units_45listKqMClVHytiMeta } from "/app/pages/testing/units-list.vue?macro=true";
import { default as units_45tabs_45contentF5rKPANIthMeta } from "/app/pages/testing/units-tabs-content.vue?macro=true";
import { default as units_45tabs4RM6q0zdqHMeta } from "/app/pages/testing/units-tabs.vue?macro=true";
import { default as validation_45messageDm8yha439IMeta } from "/app/pages/testing/validation-message.vue?macro=true";
import { default as vsl_45modalg4Cp4PsvhAMeta } from "/app/pages/testing/vsl-modal.vue?macro=true";
import { default as youtube_45videoP29lOTmJddMeta } from "/app/pages/testing/youtube-video.vue?macro=true";
import { default as indextZNotJT7GrMeta } from "/app/pages/undergraduate-certificates/index.vue?macro=true";
export default [
  {
    name: "Certificates",
    path: "/certificates",
    meta: indexily6rV3EBoMeta || {},
    component: () => import("/app/pages/certificates/index.vue")
  },
  {
    name: "course-areas-courseArea-subjectGroup",
    path: "/course-areas/:courseArea()/:subjectGroup()",
    component: () => import("/app/pages/course-areas/[courseArea]/[subjectGroup]/index.vue")
  },
  {
    name: "Course",
    path: "/course-areas/:courseArea()/courses/:courseSlug()--:courseId()",
    meta: indexFb355T7rQ0Meta || {},
    component: () => import("/app/pages/course-areas/[courseArea]/courses/[courseSlug]--[courseId]/index.vue")
  },
  {
    name: "OfferingApply",
    path: "/course-areas/:courseArea()/courses/:courseSlug()--:courseId()/offerings/:offeringId()/apply",
    meta: index6sL1PirIpuMeta || {},
    component: () => import("/app/pages/course-areas/[courseArea]/courses/[courseSlug]--[courseId]/offerings/[offeringId]/apply/index.vue")
  },
  {
    name: "OfferingBeforeYouBegin",
    path: "/course-areas/:courseArea()/courses/:courseSlug()--:courseId()/offerings/:offeringId()/before-you-begin",
    meta: indexX1DuSrOFfZMeta || {},
    component: () => import("/app/pages/course-areas/[courseArea]/courses/[courseSlug]--[courseId]/offerings/[offeringId]/before-you-begin/index.vue")
  },
  {
    name: "Offering",
    path: "/course-areas/:courseArea()/courses/:courseSlug()--:courseId()/offerings/:offeringId()",
    meta: index52R9ghZWOHMeta || {},
    component: () => import("/app/pages/course-areas/[courseArea]/courses/[courseSlug]--[courseId]/offerings/[offeringId]/index.vue")
  },
  {
    name: "OfferingReviewAndConfirm",
    path: "/course-areas/:courseArea()/courses/:courseSlug()--:courseId()/offerings/:offeringId()/review-and-confirm",
    meta: indexCc3hYtiN64Meta || {},
    component: () => import("/app/pages/course-areas/[courseArea]/courses/[courseSlug]--[courseId]/offerings/[offeringId]/review-and-confirm/index.vue")
  },
  {
    name: "course-areas-courseArea",
    path: "/course-areas/:courseArea()",
    component: () => import("/app/pages/course-areas/[courseArea]/index.vue")
  },
  {
    name: "course-areas",
    path: "/course-areas",
    component: () => import("/app/pages/course-areas/index.vue")
  },
  {
    name: "course-search",
    path: "/course-search",
    component: () => import("/app/pages/course-search/index.vue")
  },
  {
    name: "Degrees",
    path: "/degrees",
    meta: indexAdVDOkEyqxMeta || {},
    component: () => import("/app/pages/degrees/index.vue")
  },
  {
    name: "Digital",
    path: "/digital",
    meta: indexUF0w3ugNweMeta || {},
    component: () => import("/app/pages/digital/index.vue")
  },
  {
    name: "Diplomas",
    path: "/diplomas",
    meta: indexAU4KPYATzCMeta || {},
    component: () => import("/app/pages/diplomas/index.vue")
  },
  {
    name: "global-loader",
    path: "/global-loader",
    component: () => import("/app/pages/global-loader.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/app/pages/index.vue")
  },
  {
    name: "information-sessions",
    path: "/information-sessions",
    component: () => import("/app/pages/information-sessions/index.vue")
  },
  {
    name: "international-search",
    path: "/international/course-search",
    meta: indexpS0bNV3ScIMeta || {},
    component: () => import("/app/pages/international/course-search/index.vue")
  },
  {
    name: "IntCourse",
    path: "/international/courses/:courseSlug()--:courseId()",
    meta: indexGLy0JZ8q2GMeta || {},
    component: () => import("/app/pages/international/courses/[courseSlug]--[courseId]/index.vue")
  },
  {
    name: "international",
    path: "/international",
    component: () => import("/app/pages/international/index.vue")
  },
  {
    name: "IntPathway",
    path: "/international/package/:courseName()/:id()",
    meta: _91id_93Fg2SHVr8YlMeta || {},
    component: () => import("/app/pages/international/package/[courseName]/[id].vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("/app/pages/logout.vue")
  },
  {
    name: "ShortCourses",
    path: "/short-courses",
    meta: indexAfAEtJAmSYMeta || {},
    component: () => import("/app/pages/short-courses/index.vue")
  },
  {
    name: "shortlist",
    path: "/shortlist",
    component: () => import("/app/pages/shortlist.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/app/pages/sitemap/index.vue")
  },
  {
    name: "sso-callback-error",
    path: "/sso-callback-error",
    component: () => import("/app/pages/sso-callback-error.vue")
  },
  {
    name: "sso-callback",
    path: "/sso-callback",
    component: () => import("/app/pages/sso-callback.vue")
  },
  {
    name: "StudentHub",
    path: "/student-hub",
    meta: indexCi05Vc2xGlMeta || {},
    component: () => import("/app/pages/student-hub/index.vue")
  },
  {
    name: "study-seeker",
    path: "/study-seeker",
    meta: study_45seekerzMrmKJ1QNqMeta || {},
    component: () => import("/app/pages/study-seeker.vue"),
    children: [
  {
    name: "study-seeker-id",
    path: ":id()",
    meta: _91id_93vFgOiNfvIrMeta || {},
    component: () => import("/app/pages/study-seeker/[id].vue")
  }
]
  },
  {
    name: "tailwind-test",
    path: "/tailwind-test",
    component: () => import("/app/pages/tailwind-test.vue")
  },
  {
    name: "testing-accordion",
    path: "/testing/accordion",
    component: () => import("/app/pages/testing/accordion.vue")
  },
  {
    name: "testing-accreditation-logo",
    path: "/testing/accreditation-logo",
    component: () => import("/app/pages/testing/accreditation-logo.vue")
  },
  {
    name: "testing-app-form-checkbox",
    path: "/testing/app-form-checkbox",
    component: () => import("/app/pages/testing/app-form-checkbox.vue")
  },
  {
    name: "testing-app-form-date",
    path: "/testing/app-form-date",
    component: () => import("/app/pages/testing/app-form-date.vue")
  },
  {
    name: "testing-app-form-dropdown",
    path: "/testing/app-form-dropdown",
    component: () => import("/app/pages/testing/app-form-dropdown.vue")
  },
  {
    name: "testing-app-form-number",
    path: "/testing/app-form-number",
    component: () => import("/app/pages/testing/app-form-number.vue")
  },
  {
    name: "testing-app-form-radio",
    path: "/testing/app-form-radio",
    component: () => import("/app/pages/testing/app-form-radio.vue")
  },
  {
    name: "testing-app-form-text",
    path: "/testing/app-form-text",
    component: () => import("/app/pages/testing/app-form-text.vue")
  },
  {
    name: "testing-app-form",
    path: "/testing/app-form",
    component: () => import("/app/pages/testing/app-form.vue")
  },
  {
    name: "testing-app-submitted-message",
    path: "/testing/app-submitted-message",
    component: () => import("/app/pages/testing/app-submitted-message.vue")
  },
  {
    name: "testing-application-offering-card",
    path: "/testing/application-offering-card",
    component: () => import("/app/pages/testing/application-offering-card.vue")
  },
  {
    name: "testing-application-records",
    path: "/testing/application-records",
    component: () => import("/app/pages/testing/application-records.vue")
  },
  {
    name: "testing-application-status-chip",
    path: "/testing/application-status-chip",
    component: () => import("/app/pages/testing/application-status-chip.vue")
  },
  {
    name: "testing-application-status-next",
    path: "/testing/application-status-next",
    component: () => import("/app/pages/testing/application-status-next.vue")
  },
  {
    name: "testing-attribute-tile",
    path: "/testing/attribute-tile",
    component: () => import("/app/pages/testing/attribute-tile.vue")
  },
  {
    name: "testing-back-to-top-button",
    path: "/testing/back-to-top-button",
    component: () => import("/app/pages/testing/back-to-top-button.vue")
  },
  {
    name: "testing-brand-list-group",
    path: "/testing/brand-list-group",
    component: () => import("/app/pages/testing/brand-list-group.vue")
  },
  {
    name: "testing-brand-list-item",
    path: "/testing/brand-list-item",
    component: () => import("/app/pages/testing/brand-list-item.vue")
  },
  {
    name: "testing-brand-list",
    path: "/testing/brand-list",
    component: () => import("/app/pages/testing/brand-list.vue")
  },
  {
    name: "testing-breadcrumbs",
    path: "/testing/breadcrumbs",
    component: () => import("/app/pages/testing/breadcrumbs.vue")
  },
  {
    name: "testing-campus-details",
    path: "/testing/campus-details",
    component: () => import("/app/pages/testing/campus-details.vue")
  },
  {
    name: "testing-campus-facilities",
    path: "/testing/campus-facilities",
    component: () => import("/app/pages/testing/campus-facilities.vue")
  },
  {
    name: "testing-campus-map-card",
    path: "/testing/campus-map-card",
    component: () => import("/app/pages/testing/campus-map-card.vue")
  },
  {
    name: "testing-career-guide",
    path: "/testing/career-guide",
    component: () => import("/app/pages/testing/career-guide.vue")
  },
  {
    name: "testing-chip",
    path: "/testing/chip",
    component: () => import("/app/pages/testing/chip.vue")
  },
  {
    name: "testing-content-area",
    path: "/testing/content-area",
    component: () => import("/app/pages/testing/content-area.vue")
  },
  {
    name: "testing-content-card",
    path: "/testing/content-card",
    component: () => import("/app/pages/testing/content-card.vue")
  },
  {
    name: "testing-content-cards",
    path: "/testing/content-cards",
    component: () => import("/app/pages/testing/content-cards.vue")
  },
  {
    name: "testing-content-search-sort",
    path: "/testing/content-search-sort",
    component: () => import("/app/pages/testing/content-search-sort.vue")
  },
  {
    name: "testing-content-section",
    path: "/testing/content-section",
    component: () => import("/app/pages/testing/content-section.vue")
  },
  {
    name: "testing-course-area-card",
    path: "/testing/course-area-card",
    component: () => import("/app/pages/testing/course-area-card.vue")
  },
  {
    name: "testing-course-area-mobile-nav",
    path: "/testing/course-area-mobile-nav",
    component: () => import("/app/pages/testing/course-area-mobile-nav.vue")
  },
  {
    name: "testing-course-card-accordion",
    path: "/testing/course-card-accordion",
    component: () => import("/app/pages/testing/course-card-accordion.vue")
  },
  {
    name: "testing-course-card",
    path: "/testing/course-card",
    component: () => import("/app/pages/testing/course-card.vue")
  },
  {
    name: "testing-course-desktop-nav",
    path: "/testing/course-desktop-nav",
    component: () => import("/app/pages/testing/course-desktop-nav.vue")
  },
  {
    name: "testing-course-guide",
    path: "/testing/course-guide",
    component: () => import("/app/pages/testing/course-guide.vue")
  },
  {
    name: "testing-course-mobile-nav",
    path: "/testing/course-mobile-nav",
    component: () => import("/app/pages/testing/course-mobile-nav.vue")
  },
  {
    name: "testing-course-search-card",
    path: "/testing/course-search-card",
    component: () => import("/app/pages/testing/course-search-card.vue")
  },
  {
    name: "testing-course-type-filters-mobile",
    path: "/testing/course-type-filters-mobile",
    component: () => import("/app/pages/testing/course-type-filters-mobile.vue")
  },
  {
    name: "testing-course-type-filters",
    path: "/testing/course-type-filters",
    component: () => import("/app/pages/testing/course-type-filters.vue")
  },
  {
    name: "testing-dashboard-header",
    path: "/testing/dashboard-header",
    component: () => import("/app/pages/testing/dashboard-header.vue")
  },
  {
    name: "testing-draft-application",
    path: "/testing/draft-application",
    component: () => import("/app/pages/testing/draft-application.vue")
  },
  {
    name: "testing-duration-filter-section",
    path: "/testing/duration-filter-section",
    component: () => import("/app/pages/testing/duration-filter-section.vue")
  },
  {
    name: "testing-enrolled-applications",
    path: "/testing/enrolled-applications",
    component: () => import("/app/pages/testing/enrolled-applications.vue")
  },
  {
    name: "testing-environment-variables",
    path: "/testing/environment-variables",
    component: () => import("/app/pages/testing/environment-variables.vue")
  },
  {
    name: "testing-error-application-card",
    path: "/testing/error-application-card",
    component: () => import("/app/pages/testing/error-application-card.vue")
  },
  {
    name: "testing-fee-calculator-container",
    path: "/testing/fee-calculator-container",
    component: () => import("/app/pages/testing/fee-calculator-container.vue")
  },
  {
    name: "testing-fee-calculator-controls",
    path: "/testing/fee-calculator-controls",
    component: () => import("/app/pages/testing/fee-calculator-controls.vue")
  },
  {
    name: "testing-fee-calculator-eligible",
    path: "/testing/fee-calculator-eligible",
    component: () => import("/app/pages/testing/fee-calculator-eligible.vue")
  },
  {
    name: "testing-fee-calculator-not-eligible",
    path: "/testing/fee-calculator-not-eligible",
    component: () => import("/app/pages/testing/fee-calculator-not-eligible.vue")
  },
  {
    name: "testing-fee-calculator-stepper",
    path: "/testing/fee-calculator-stepper",
    component: () => import("/app/pages/testing/fee-calculator-stepper.vue")
  },
  {
    name: "testing-fee-details",
    path: "/testing/fee-details",
    component: () => import("/app/pages/testing/fee-details.vue")
  },
  {
    name: "testing-fetch-error",
    path: "/testing/fetch-error",
    component: () => import("/app/pages/testing/fetch-error.vue")
  },
  {
    name: "testing-fetch-loading",
    path: "/testing/fetch-loading",
    component: () => import("/app/pages/testing/fetch-loading.vue")
  },
  {
    name: "testing-filter-chips",
    path: "/testing/filter-chips",
    component: () => import("/app/pages/testing/filter-chips.vue")
  },
  {
    name: "testing-filter-dropdown",
    path: "/testing/filter-dropdown",
    component: () => import("/app/pages/testing/filter-dropdown.vue")
  },
  {
    name: "testing-filter-section",
    path: "/testing/filter-section",
    component: () => import("/app/pages/testing/filter-section.vue")
  },
  {
    name: "testing-google-maps-autocomplete",
    path: "/testing/google-maps-autocomplete",
    component: () => import("/app/pages/testing/google-maps-autocomplete.vue")
  },
  {
    name: "testing-hed-units-list",
    path: "/testing/hed-units-list",
    component: () => import("/app/pages/testing/hed-units-list.vue")
  },
  {
    name: "testing-hed-units-tabs-content",
    path: "/testing/hed-units-tabs-content",
    component: () => import("/app/pages/testing/hed-units-tabs-content.vue")
  },
  {
    name: "testing-hed-units-tabs",
    path: "/testing/hed-units-tabs",
    component: () => import("/app/pages/testing/hed-units-tabs.vue")
  },
  {
    name: "testing-help-buttons",
    path: "/testing/help-buttons",
    component: () => import("/app/pages/testing/help-buttons.vue")
  },
  {
    name: "testing-hero-banner",
    path: "/testing/hero-banner",
    component: () => import("/app/pages/testing/hero-banner.vue")
  },
  {
    name: "testing",
    path: "/testing",
    component: () => import("/app/pages/testing/index.vue")
  },
  {
    name: "testing-info-session-card",
    path: "/testing/info-session-card",
    component: () => import("/app/pages/testing/info-session-card.vue")
  },
  {
    name: "testing-info-session-dropdown",
    path: "/testing/info-session-dropdown",
    component: () => import("/app/pages/testing/info-session-dropdown.vue")
  },
  {
    name: "testing-info-session-filter-chips",
    path: "/testing/info-session-filter-chips",
    component: () => import("/app/pages/testing/info-session-filter-chips.vue")
  },
  {
    name: "testing-info-session-filters",
    path: "/testing/info-session-filters",
    component: () => import("/app/pages/testing/info-session-filters.vue")
  },
  {
    name: "testing-int-course-card",
    path: "/testing/int-course-card",
    component: () => import("/app/pages/testing/int-course-card.vue")
  },
  {
    name: "testing-int-dropdown",
    path: "/testing/int-dropdown",
    component: () => import("/app/pages/testing/int-dropdown.vue")
  },
  {
    name: "testing-int-filter-chips",
    path: "/testing/int-filter-chips",
    component: () => import("/app/pages/testing/int-filter-chips.vue")
  },
  {
    name: "testing-int-filter",
    path: "/testing/int-filter",
    component: () => import("/app/pages/testing/int-filter.vue")
  },
  {
    name: "testing-int-mobile-filter",
    path: "/testing/int-mobile-filter",
    component: () => import("/app/pages/testing/int-mobile-filter.vue")
  },
  {
    name: "testing-int-package-details",
    path: "/testing/int-package-details",
    component: () => import("/app/pages/testing/int-package-details.vue")
  },
  {
    name: "testing-int-search-bar-slot",
    path: "/testing/int-search-bar-slot",
    component: () => import("/app/pages/testing/int-search-bar-slot.vue")
  },
  {
    name: "testing-latest-partners-list",
    path: "/testing/latest-partners-list",
    component: () => import("/app/pages/testing/latest-partners-list.vue")
  },
  {
    name: "testing-lazy-img",
    path: "/testing/lazy-img",
    component: () => import("/app/pages/testing/lazy-img.vue")
  },
  {
    name: "testing-lineclamp-container",
    path: "/testing/lineclamp-container",
    component: () => import("/app/pages/testing/lineclamp-container.vue")
  },
  {
    name: "testing-location-filter-section",
    path: "/testing/location-filter-section",
    component: () => import("/app/pages/testing/location-filter-section.vue")
  },
  {
    name: "testing-logging",
    path: "/testing/logging",
    component: () => import("/app/pages/testing/logging.vue")
  },
  {
    name: "testing-mobile-filters-overlay",
    path: "/testing/mobile-filters-overlay",
    component: () => import("/app/pages/testing/mobile-filters-overlay.vue")
  },
  {
    name: "testing-modal",
    path: "/testing/modal",
    component: () => import("/app/pages/testing/modal.vue")
  },
  {
    name: "testing-offering-cta-button-external",
    path: "/testing/offering-cta-button-external",
    component: () => import("/app/pages/testing/offering-cta-button-external.vue")
  },
  {
    name: "testing-offering-cta-button-internal",
    path: "/testing/offering-cta-button-internal",
    component: () => import("/app/pages/testing/offering-cta-button-internal.vue")
  },
  {
    name: "testing-offering-cta-button",
    path: "/testing/offering-cta-button",
    component: () => import("/app/pages/testing/offering-cta-button.vue")
  },
  {
    name: "testing-offering-details",
    path: "/testing/offering-details",
    component: () => import("/app/pages/testing/offering-details.vue")
  },
  {
    name: "testing-offering-list-card",
    path: "/testing/offering-list-card",
    component: () => import("/app/pages/testing/offering-list-card.vue")
  },
  {
    name: "testing-offering-list",
    path: "/testing/offering-list",
    component: () => import("/app/pages/testing/offering-list.vue")
  },
  {
    name: "testing-offering-search",
    path: "/testing/offering-search",
    component: () => import("/app/pages/testing/offering-search.vue")
  },
  {
    name: "testing-offering-status-chip",
    path: "/testing/offering-status-chip",
    component: () => import("/app/pages/testing/offering-status-chip.vue")
  },
  {
    name: "testing-offering-status",
    path: "/testing/offering-status",
    component: () => import("/app/pages/testing/offering-status.vue")
  },
  {
    name: "testing-offering-summary-tile",
    path: "/testing/offering-summary-tile",
    component: () => import("/app/pages/testing/offering-summary-tile.vue")
  },
  {
    name: "testing-page-header-buttons",
    path: "/testing/page-header-buttons",
    component: () => import("/app/pages/testing/page-header-buttons.vue")
  },
  {
    name: "testing-page-header",
    path: "/testing/page-header",
    component: () => import("/app/pages/testing/page-header.vue")
  },
  {
    name: "testing-pagination",
    path: "/testing/pagination",
    component: () => import("/app/pages/testing/pagination.vue")
  },
  {
    name: "testing-progress-bar",
    path: "/testing/progress-bar",
    component: () => import("/app/pages/testing/progress-bar.vue")
  },
  {
    name: "testing-promotion-card",
    path: "/testing/promotion-card",
    component: () => import("/app/pages/testing/promotion-card.vue")
  },
  {
    name: "testing-promotions",
    path: "/testing/promotions",
    component: () => import("/app/pages/testing/promotions.vue")
  },
  {
    name: "testing-resources",
    path: "/testing/resources",
    component: () => import("/app/pages/testing/resources.vue")
  },
  {
    name: "testing-search-autocomplete",
    path: "/testing/search-autocomplete",
    component: () => import("/app/pages/testing/search-autocomplete.vue")
  },
  {
    name: "testing-search-filters",
    path: "/testing/search-filters",
    component: () => import("/app/pages/testing/search-filters.vue")
  },
  {
    name: "testing-side-nav",
    path: "/testing/side-nav",
    component: () => import("/app/pages/testing/side-nav.vue")
  },
  {
    name: "testing-spinner",
    path: "/testing/spinner",
    component: () => import("/app/pages/testing/spinner.vue")
  },
  {
    name: "testing-spy-scroll",
    path: "/testing/spy-scroll",
    component: () => import("/app/pages/testing/spy-scroll.vue")
  },
  {
    name: "testing-static-card-group",
    path: "/testing/static-card-group",
    component: () => import("/app/pages/testing/static-card-group.vue")
  },
  {
    name: "testing-static-card",
    path: "/testing/static-card",
    component: () => import("/app/pages/testing/static-card.vue")
  },
  {
    name: "testing-stepper-checkbox",
    path: "/testing/stepper-checkbox",
    component: () => import("/app/pages/testing/stepper-checkbox.vue")
  },
  {
    name: "testing-stepper-dropdown",
    path: "/testing/stepper-dropdown",
    component: () => import("/app/pages/testing/stepper-dropdown.vue")
  },
  {
    name: "testing-stepper-number",
    path: "/testing/stepper-number",
    component: () => import("/app/pages/testing/stepper-number.vue")
  },
  {
    name: "testing-stepper-postcode",
    path: "/testing/stepper-postcode",
    component: () => import("/app/pages/testing/stepper-postcode.vue")
  },
  {
    name: "testing-stepper-radio",
    path: "/testing/stepper-radio",
    component: () => import("/app/pages/testing/stepper-radio.vue")
  },
  {
    name: "testing-study-seeker-controls",
    path: "/testing/study-seeker-controls",
    component: () => import("/app/pages/testing/study-seeker-controls.vue")
  },
  {
    name: "testing-study-seeker-header",
    path: "/testing/study-seeker-header",
    component: () => import("/app/pages/testing/study-seeker-header.vue")
  },
  {
    name: "testing-study-seeker-list-item",
    path: "/testing/study-seeker-list-item",
    component: () => import("/app/pages/testing/study-seeker-list-item.vue")
  },
  {
    name: "testing-study-seeker-results-list",
    path: "/testing/study-seeker-results-list",
    component: () => import("/app/pages/testing/study-seeker-results-list.vue")
  },
  {
    name: "testing-study-seeker-results",
    path: "/testing/study-seeker-results",
    component: () => import("/app/pages/testing/study-seeker-results.vue")
  },
  {
    name: "testing-study-seeker-stepper",
    path: "/testing/study-seeker-stepper",
    component: () => import("/app/pages/testing/study-seeker-stepper.vue")
  },
  {
    name: "testing-study-seeker-store",
    path: "/testing/study-seeker-store",
    component: () => import("/app/pages/testing/study-seeker-store.vue")
  },
  {
    name: "testing-subjectgroup-sidenav",
    path: "/testing/subjectgroup-sidenav",
    component: () => import("/app/pages/testing/subjectgroup-sidenav.vue")
  },
  {
    name: "testing-subsidy-details",
    path: "/testing/subsidy-details",
    component: () => import("/app/pages/testing/subsidy-details.vue")
  },
  {
    name: "testing-table",
    path: "/testing/table",
    component: () => import("/app/pages/testing/table.vue")
  },
  {
    name: "testing-tabs-generic",
    path: "/testing/tabs-generic",
    component: () => import("/app/pages/testing/tabs-generic.vue")
  },
  {
    name: "testing-tabs-slot",
    path: "/testing/tabs-slot",
    component: () => import("/app/pages/testing/tabs-slot.vue")
  },
  {
    name: "testing-tafe-button",
    path: "/testing/tafe-button",
    component: () => import("/app/pages/testing/tafe-button.vue")
  },
  {
    name: "testing-tafe-link",
    path: "/testing/tafe-link",
    component: () => import("/app/pages/testing/tafe-link.vue")
  },
  {
    name: "testing-tafe-tile",
    path: "/testing/tafe-tile",
    component: () => import("/app/pages/testing/tafe-tile.vue")
  },
  {
    name: "testing-tafe-tiles",
    path: "/testing/tafe-tiles",
    component: () => import("/app/pages/testing/tafe-tiles.vue")
  },
  {
    name: "testing-testimonial-card",
    path: "/testing/testimonial-card",
    component: () => import("/app/pages/testing/testimonial-card.vue")
  },
  {
    name: "testing-testimonial-cards",
    path: "/testing/testimonial-cards",
    component: () => import("/app/pages/testing/testimonial-cards.vue")
  },
  {
    name: "testing-tooltip",
    path: "/testing/tooltip",
    component: () => import("/app/pages/testing/tooltip.vue")
  },
  {
    name: "testing-transition-group",
    path: "/testing/transition-group",
    component: () => import("/app/pages/testing/transition-group.vue")
  },
  {
    name: "testing-units-list",
    path: "/testing/units-list",
    component: () => import("/app/pages/testing/units-list.vue")
  },
  {
    name: "testing-units-tabs-content",
    path: "/testing/units-tabs-content",
    component: () => import("/app/pages/testing/units-tabs-content.vue")
  },
  {
    name: "testing-units-tabs",
    path: "/testing/units-tabs",
    component: () => import("/app/pages/testing/units-tabs.vue")
  },
  {
    name: "testing-validation-message",
    path: "/testing/validation-message",
    component: () => import("/app/pages/testing/validation-message.vue")
  },
  {
    name: "testing-vsl-modal",
    path: "/testing/vsl-modal",
    component: () => import("/app/pages/testing/vsl-modal.vue")
  },
  {
    name: "testing-youtube-video",
    path: "/testing/youtube-video",
    component: () => import("/app/pages/testing/youtube-video.vue")
  },
  {
    name: "UndergraduateCertificates",
    path: "/undergraduate-certificates",
    meta: indextZNotJT7GrMeta || {},
    component: () => import("/app/pages/undergraduate-certificates/index.vue")
  }
]